



































import { Component, Vue } from 'vue-property-decorator';

import { Jwt } from '@minddocdev/lib-common/users';
import { postConfirmEmailChange } from '@minddocdev/lib-web/api/services/users';
import { getAppForGroup } from '@minddocdev/lib-web/browser/url.utils';
import PasswordInput from '@minddocdev/lib-web/components/inputs/PasswordInput.vue';

import { addBreadcrumb, captureException } from '@sentry/vue';
import jwtDecode from 'jwt-decode';

@Component({ components: { PasswordInput } })
export default class ChangeEmail extends Vue {
  password = '';
  errorMessage = '';
  loading = false;

  async onSubmit() {
    addBreadcrumb({
      category: 'email-change',
      message: 'Verifying password and OTP',
    });
    this.loading = true;
    const { otp } = this.$route.params;
    try {
      const { accessToken } = await postConfirmEmailChange(this.password, otp);
      const { group } = jwtDecode(accessToken) as Jwt;
      window.location.href = getAppForGroup(group, window.location.hostname);
    } catch (e) {
      captureException(e);
      this.errorMessage = this.$t('errorMessage') as string;
    }
    this.loading = false;
  }
}
